<template>
  <div class="px-5">
    <basic-form :title="title" @save="save" :isLoading.sync="loading">
      <template slot="fields">
        <div class="flex flex-col md:flex-row justify-center">
          <div class="w-full md:w-full mr-3">
            <t-input-group
              :feedback="
                showValidation && errors.first('url_picture')
                  ? errors.first('url_picture')
                  : ''
              "
              :variant="
                showValidation && errors.first('url_picture') ? 'danger' : ''
              "
              class="w-full md:mr-0 md:ml-2 md:mr-2 my-4"
            >
              <label class="block text-left font-bold"
                >Imagen de cabecera</label
              >
              <div class="mx-auto w-full md:w-1/2 mx-auto">
                <t-upload-file
                  :disabled="loading"
                  :file.sync="form.url_picture"
                  :externalUrl="
                    typeof form.url_picture === 'string' ? form.url_picture : ''
                  "
                  backgroundColor="bg-white"
                  placeholder="Selecciona una imagen"
                  height="250px"
                  accept="image/*"
                />
              </div>
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('name')
                  ? errors.first('name')
                  : ''
              "
              :variant="showValidation && errors.first('name') ? 'danger' : ''"
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Título'"
            >
              <t-input
                v-validate="'required|max:200'"
                data-vv-validate-on="input"
                v-model="form.title"
                name="name"
                placeholder="Título de la noticia"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('name') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('description')
                  ? errors.first('description')
                  : ''
              "
              :variant="
                showValidation && errors.first('description') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Descrición breve'"
            >
              <t-input
                v-validate="'required|max:100'"
                data-vv-validate-on="input"
                v-model="form.description"
                name="description"
                placeholder="Breve descripción de la noticia"
                autocomplete="off"
                :disabled="loading"
                type="text"
                :variant="
                  showValidation && errors.first('description') ? 'danger' : ''
                "
              />
            </t-input-group>

            <t-input-group
              :feedback="
                showValidation && errors.first('id_category')
                  ? errors.first('id_category')
                  : ''
              "
              :variant="
                showValidation && errors.first('id_category') ? 'danger' : ''
              "
              class="w-full md:ml-2 md:mr-2 my-4"
              :label="'Categoría'"
            >
              <t-select
                v-model="form.id_notice_category"
                placeholder="Categoría"
                name="id_category"
                :options="notice_categories"
                textAttribute="name"
                valueAttribute="id"
                :variant="
                  showValidation && errors.first('id_category') ? 'danger' : ''
                "
              ></t-select>
            </t-input-group>

            <!-- START EDITOR -->
            <vue-editor
              :editorOptions="editorSettings"
              v-model="form.body"
            ></vue-editor>
            <!-- END EDITOR -->
          </div>
        </div>
      </template>
    </basic-form>
  </div>
</template>
<script src="/node_modules/quill-image-resize-module/image-resize.min.js"></script>
<script>
import { VueEditor, Quill } from "vue2-editor"
import { ImageDrop } from "quill-image-drop-module"
import ImageResize from "fixed-quill-image-resize/image-resize.min.js"
Quill.register("modules/imageDrop", ImageDrop)
Quill.register("modules/imageResize", ImageResize)
const notify = () => import("@/utils/notify.js")
export default {
  components: {
    VueEditor
  },
  props: {
    title: {
      type: String
    },
    deletable: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      progress: false,
      file: null,
      file_name: null,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: "photos",
      editorSettings: {
        modules: {
          imageDrop: true,
          imageResize: {}
        }
      },
      form: {
        id_notice_category: null,
        body_only_text: null,
        body_short: null,
        url_picture: null,
        description: null,
        title: null,
        body: null
      },
      showValidation: false
    }
  },
  computed: {
    notice_categories() {
      return this.$store.getters["global/getItems"](
        "noticeCategory",
        "unpaginated_elements"
      )
    },
    user() {
      return this.$store.getters["auth/getUser"]
    }
  },
  methods: {
    getItems(route, module, master) {
      this.$store
        .dispatch("global/getItems", {
          module: module,
          route: route,
          noPaginate: true,
          isMaster: master,
          params: {
            active: 1,
            order_key: "name",
            order_value: "asc"
          }
        })
        .catch((error) => {
          notify().then(({ notification }) => {
            notification("error", error.code, this.$snotify)
          })
        })
    },
    save() {
      this.$validator.validateAll().then((isValid) => {
        this.showValidation = true
        if (isValid) {
          this.$emit("save")
        }
      })
    },
    deleteElement() {
      this.$dialog
        .confirm({
          title: "¿Estás seguro?",
          text: `Esta acción eliminará permanentemente el torneo.`,
          icon: "question",
          escToClose: true,
          cancelButtonText: "Cancelar",
          okButtonText: "Ok"
        })
        .then((result) => {
          if (result.isOk) {
            this.$store
              .dispatch("global/changeStatus", {
                payload: {
                  id: this.form.id,
                  status: 2
                },
                route: "/notice",
                module: "notice"
              })
              .then((response) => {
                notify().then(({ notification }) => {
                  notification(
                    "master-status-delete",
                    response.code,
                    this.$snotify
                  )
                })
                if (response.code === 200) this.$router.push("/noticias")
              })
              .catch((error) => {
                notify().then(({ notification }) => {
                  notification("error", error.code, this.$snotify)
                })
              })
          }
        })
    }
  },
  created() {
    this.getItems("/notice_category/all", "noticeCategory", true)
  }
}
</script>
