var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-5"},[_c('basic-form',{attrs:{"title":_vm.title,"isLoading":_vm.loading},on:{"save":_vm.save,"update:isLoading":function($event){_vm.loading=$event},"update:is-loading":function($event){_vm.loading=$event}}},[_c('template',{slot:"fields"},[_c('div',{staticClass:"flex flex-col md:flex-row justify-center"},[_c('div',{staticClass:"w-full md:w-full mr-3"},[_c('t-input-group',{staticClass:"w-full md:mr-0 md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('url_picture')
                ? _vm.errors.first('url_picture')
                : '',"variant":_vm.showValidation && _vm.errors.first('url_picture') ? 'danger' : ''}},[_c('label',{staticClass:"block text-left font-bold"},[_vm._v("Imagen de cabecera")]),_c('div',{staticClass:"mx-auto w-full md:w-1/2 mx-auto"},[_c('t-upload-file',{attrs:{"disabled":_vm.loading,"file":_vm.form.url_picture,"externalUrl":typeof _vm.form.url_picture === 'string' ? _vm.form.url_picture : '',"backgroundColor":"bg-white","placeholder":"Selecciona una imagen","height":"250px","accept":"image/*"},on:{"update:file":function($event){return _vm.$set(_vm.form, "url_picture", $event)}}})],1)]),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('name')
                ? _vm.errors.first('name')
                : '',"variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : '',"label":'Título'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:200'),expression:"'required|max:200'"}],attrs:{"data-vv-validate-on":"input","name":"name","placeholder":"Título de la noticia","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('name') ? 'danger' : ''},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('description')
                ? _vm.errors.first('description')
                : '',"variant":_vm.showValidation && _vm.errors.first('description') ? 'danger' : '',"label":'Descrición breve'}},[_c('t-input',{directives:[{name:"validate",rawName:"v-validate",value:('required|max:100'),expression:"'required|max:100'"}],attrs:{"data-vv-validate-on":"input","name":"description","placeholder":"Breve descripción de la noticia","autocomplete":"off","disabled":_vm.loading,"type":"text","variant":_vm.showValidation && _vm.errors.first('description') ? 'danger' : ''},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1),_c('t-input-group',{staticClass:"w-full md:ml-2 md:mr-2 my-4",attrs:{"feedback":_vm.showValidation && _vm.errors.first('id_category')
                ? _vm.errors.first('id_category')
                : '',"variant":_vm.showValidation && _vm.errors.first('id_category') ? 'danger' : '',"label":'Categoría'}},[_c('t-select',{attrs:{"placeholder":"Categoría","name":"id_category","options":_vm.notice_categories,"textAttribute":"name","valueAttribute":"id","variant":_vm.showValidation && _vm.errors.first('id_category') ? 'danger' : ''},model:{value:(_vm.form.id_notice_category),callback:function ($$v) {_vm.$set(_vm.form, "id_notice_category", $$v)},expression:"form.id_notice_category"}})],1),_c('vue-editor',{attrs:{"editorOptions":_vm.editorSettings},model:{value:(_vm.form.body),callback:function ($$v) {_vm.$set(_vm.form, "body", $$v)},expression:"form.body"}})],1)])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }